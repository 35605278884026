import { useSelector } from 'react-redux'

import { splitDetail } from 'features/tab/tab'
import { useEffect, useState } from 'react'
import { SplitType } from 'enums/split-type'
import tabService from 'services/tab.service'

import SplitByGuestDialog from 'components/SplitByGuestDialog/Index'

type ProcessableSplitType =
  | 'split_by_guest'
  | 'split_by_item'
  | 'split_by_seat'
  | 'pay_by_check'
  | 'pay_in_full'
  | ''

type UseSplitDetail = {
  isSplit: boolean
  splitType: SplitType
  processableSplitType: ProcessableSplitType
  noOfGuests: number
  noOfPayingGuests: number
  splitByGuestComponent: React.ReactNode
}

function useSplitDetail(): UseSplitDetail {
  const { isSplitDone, splitType, noOfGuest, noOfPayingGuest } =
    useSelector(splitDetail)

  const [isOpen, setIsOpen] = useState(false)

  const setSplitData = async (guestNo?: number) => {
    await tabService.setSplitData(SplitType.Unknown, guestNo)
  }

  useEffect(() => {
    switch (splitType) {
      case 8:
        sessionStorage.setItem('paymentModeId', '8')
        break
      case 5:
        sessionStorage.setItem('paymentModeId', '5')
        break
      case 7:
        sessionStorage.setItem('paymentModeId', '7')
        break
      case 6:
        sessionStorage.setItem('paymentModeId', '6')
        break
      case 9:
        sessionStorage.setItem('paymentModeId', '9')
        break
      default:
        sessionStorage.setItem('paymentModeId', '0')
    }
  }, [splitType])

  const getProcessableSplitType = (): ProcessableSplitType => {
    switch (splitType) {
      case SplitType.Guest:
        return 'split_by_guest'
      case SplitType.Item:
        return 'split_by_item'
      case SplitType.Seat:
        return 'split_by_seat'
      case SplitType.Check:
        return 'pay_by_check'
      case SplitType.Full:
        return 'pay_in_full'
      default:
        return ''
    }
  }

  return {
    isSplit: isSplitDone,
    splitType,
    processableSplitType: getProcessableSplitType(),
    noOfGuests: noOfGuest,
    noOfPayingGuests: noOfPayingGuest,
    splitByGuestComponent: (
      <SplitByGuestDialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        closeCallback={(e: SplitType, guestNo: number) => {
          setSplitData(guestNo)
        }}
      />
    ),
  }
}

export { useSplitDetail }
