import { useState } from 'react'

import TipRadio from 'components/TipRadio'

import { useTabTotals } from './useTabTotals'

function useTip() {
  const { total } = useTabTotals()

  const [tip, setTip] = useState(0)
  const [isCustom, setIsCustom] = useState(false)
  const [selectedPercentage, setSelectedPercentage] = useState(0)

  const setCustomTip = (customTip: number) => {
    setIsCustom(true)
    setTip(customTip)
  }

  return {
    tip,
    selectedPercentage,
    isCustomTip: isCustom,
    setCustomTip,
    tipComponent: (
      <div className="card bordered tips-wrapper">
        <span>
          <strong>Would you like to add tip?</strong>
          <small>${tip.toFixed(2) || 0}</small>
        </span>
        <TipRadio
          isTipEdited={(val: boolean) => val} // This did not do anything, putting in a stub function to be called until it can be refactored
          setTipAmount={setTip}
          totalAmt={total}
          setCustomTip={setCustomTip}
          setIsCustomTip={setIsCustom}
          setTipPercentage={setSelectedPercentage}
        />
      </div>
    ),
  }
}

export { useTip }
