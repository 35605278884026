import { PromoCode } from 'interfaces/promocode'
import { Box, Button, Grid, TextField, Typography } from '@mui/material'

interface Props {
  promoCode: PromoCode | undefined
  input: string
  error: string | undefined
  onChange: (input: string) => void
  onClick: VoidFunction
  onDelete: VoidFunction
}

function Promo(props: Props) {
  const { promoCode, input, error, onChange, onClick, onDelete } = props
  return (
    <div className="card bordered tips-wrapper">
      <span>
        <strong>Do you have a Promo Code?</strong>
        {promoCode?.promocodeId > 0 && (
          <small style={{ color: 'green' }}>
            ${promoCode.promocodeAmount?.toFixed(2) || 0}
          </small>
        )}
      </span>
      <Grid container spacing={3}>
        <Grid item sm={6} xs={12}>
          {promoCode?.promocodeId != null && promoCode?.promocodeId > 0 ? (
            <TextField
              variant="standard"
              type="text"
              label="Promo Code"
              value={input}
              id="applyPromo"
              InputProps={{ readOnly: true }} // Make the input read-only
              InputLabelProps={{
                shrink: true,
              }}
            />
          ) : (
            <TextField
              variant="standard"
              type="text"
              label="Promo Code"
              name="promocode"
              value={input}
              id="applyPromo"
              error={error != null}
              helperText={error ?? ''}
              InputLabelProps={{
                shrink: input != null && input.length > 0,
              }}
              onChange={(event) => onChange(event.target.value.toUpperCase())}
            />
          )}
        </Grid>
        <Grid
          item
          sm={6}
          xs={12}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <div className="apply-btn">
            <Button
              color="primary"
              className="change-split apply-promo-btn"
              variant="contained"
              title={
                promoCode?.promocodeId > 0
                  ? 'Remove Promocode'
                  : 'Apply Promocode'
              }
              id="applyPromo"
              onClick={() => {
                if (promoCode?.promocodeId > 0) {
                  onDelete()
                } else {
                  onClick()
                }
              }}
            >
              {promoCode?.promocodeId > 0 ? (
                <span>Remove</span>
              ) : (
                <span>Apply</span>
              )}
            </Button>
          </div>
        </Grid>
      </Grid>
      {promoCode?.promocodeId > 0 && promoCode?.description !== '' && (
        <>
          <Grid container xs={12} style={{ marginTop: '10px' }}>
            <Box sx={{ width: '50%' }}>
              <Typography variant="caption">{promoCode.description}</Typography>
            </Box>
          </Grid>
        </>
      )}
    </div>
  )
}

export { Promo }
