import { IPaymentToBeDone } from 'interfaces/payment'

function calculateOrderTipAmount(
  orderTotalNoTax: number,
  totalBillNoTax: number,
  tip: number
): number {
  // don't need tax since we're just calculating proportion here.
  const percentageOfTotalBill = orderTotalNoTax / totalBillNoTax
  return tip * percentageOfTotalBill
}

function buildPaymentOrder(
  ordersToBePaid: IPaymentToBeDone[],
  totalBillNoTax: number,
  tip: number
) {
  return (
    ordersToBePaid?.map((order) => {
      return {
        orderId: order.orderId,
        posOrderId: order.posOrderId,
        subTotal: order.totalBillAmount,
        taxTotal: order.totalTax,
        serviceCharge: order.totalServiceCharges,
        tipAmount: calculateOrderTipAmount(
          order.totalBillAmount,
          totalBillNoTax,
          tip
        ),
      }
    }) ?? []
  )
}

export { buildPaymentOrder, calculateOrderTipAmount }
