import { Container } from '@mui/material'
import AlertDialog from 'components/AlertDialog/Index'
import Footer from 'components/Footer/Index'
import Layout from 'components/Layout/Layout'
import PaymentProcessDialog from 'components/PaymentProcessDialog/Index'
import { ChangeSplit } from 'components/ChangeSplit'
import { setPaymentInProcess } from 'features/paymentProgress/paymentProgress'
import { tabDetail } from 'features/tab/tab'
import { useSplitDetail } from 'hooks/useSplitDetail'

import { IGetToBePaidResponse } from 'interfaces/payment'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import authService from 'services/auth.service'
import paymentService from 'services/payment.service'
import { useTabTotals } from 'hooks/useTabTotals'
import { useItems } from 'hooks/useItems'
import { useTip } from 'hooks/useTip'
import { usePromo } from 'hooks/usePromo'

function Payment() {
  const dispatch = useDispatch()

  const { noOfPayingGuests, splitByGuestComponent } = useSplitDetail()
  const { items } = useItems()

  const { promo, promoComponent } = usePromo()
  const { subtotal, tax, total, onSubtotalChange, onTaxChange } = useTabTotals()

  const {
    tip,
    isCustomTip,
    selectedPercentage: selectedTipPercentage,
    tipComponent,
  } = useTip()

  const [openAlertDialog, setOpenALertDialog] = useState(false)
  const [splitMsg, setSplitMsg] = useState('')
  const [serviceCharge, setServiceCharge] = React.useState(0)
  const navigate = useNavigate()
  const tabDetails = useSelector(tabDetail)

  const [posDiscount, setPosDiscount] = useState(0)
  const [openPaymentProcessDialog, setOpenPaymentProcessDialog] =
    useState(false)
  const [payToBeDoneRes, setPayToBeDoneRes] = useState(null)
  const setLoaderForPaymentProcess = useCallback(
    (flag: boolean) => {
      dispatch(setPaymentInProcess(flag))
    },
    [dispatch]
  )

  const getMsg = async () => {
    const data = await authService.getWarningMsg()
    if (data.data.data.posPaymentWarning) {
      setSplitMsg(data.data.data.posPaymentWarning)
      setOpenALertDialog(true)
      setLoaderForPaymentProcess(false)
    }
    return false
  }

  const getToBePaidAPI = useCallback(async () => {
    dispatch(setPaymentInProcess(true))
    const payload = {
      itemIds: items.map((item) => item.itemId),
      tabId: tabDetails?.tabId,
      isPaymentRequest: false,
      noOfPayingGuest: noOfPayingGuests || 1,
    }
    const toBePaidResponse = await paymentService.getToBePaid(payload)
    setPayToBeDoneRes(toBePaidResponse?.data?.data)
    setServiceCharge(toBePaidResponse.data.data?.totalServiceCharges)
    const toBePaidData: IGetToBePaidResponse = toBePaidResponse?.data?.data
    if (toBePaidData?.paymentToBeDones[0]?.message) {
      setSplitMsg(
        'It looks like someone is trying to pay for your tab! Click "Ok" to be redirected to the main tab.'
      )
      setOpenALertDialog(true)
    } else if (toBePaidData.totalBillAmount <= 0) {
      await getMsg()
    } else {
      onSubtotalChange(toBePaidData.totalBillAmount)
      onTaxChange(toBePaidData.totalTax)
      setPosDiscount(toBePaidData.totalDiscounts)
    }
    dispatch(setPaymentInProcess(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getToBePaidAPI()
  }, [getToBePaidAPI])

  useEffect(() => {
    if (!subtotal) {
      navigate('/main-menu')
    }
  }, [subtotal, navigate])

  const handleSave = async () => {
    setOpenPaymentProcessDialog(true)
  }

  const totalToBePaid = Number((total + tip + serviceCharge).toFixed(2))

  return (
    <>
      <Layout title="Payment | FasTab">
        <section className="wrapper with-footer">
          <Container maxWidth="md">
            <ChangeSplit tabDetails={tabDetails} />
            {tipComponent}
            {promoComponent}
            {/* total-due start */}
            <div className="card bordered total-due" id="pricing-section">
              <ul>
                <li>
                  <span>Sub-Total</span>
                  <span>${subtotal?.toFixed(2)}</span>
                </li>
                <li>
                  <span>Tax</span>
                  <span>${tax?.toFixed(2)}</span>
                </li>
                <li>
                  <span>Total</span>
                  <span>${total.toFixed(2)}</span>
                </li>
                {serviceCharge > 0 && (
                  <li>
                    <span>Gratuity Charge</span>
                    <span>${serviceCharge?.toFixed(2) || 0}</span>
                  </li>
                )}
                <li>
                  <span>Tip</span>
                  <span>${tip.toFixed(2) || 0}</span>
                </li>
                {posDiscount > 0 && (
                  <li>
                    <span>POS Discounts</span>
                    <span> ${posDiscount?.toFixed(2) || 0.0}</span>
                  </li>
                )}
                {promo?.promocodeAmount > 0 && (
                  <li>
                    <span>Promo Code Amount</span>
                    <span style={{ color: 'green' }}>
                      - ${promo.promocodeAmount?.toFixed(2) || 0}
                    </span>
                  </li>
                )}

                <li className="grand-total">
                  <strong>Total Due</strong>
                  <strong>
                    $
                    {(
                      total +
                      tip +
                      serviceCharge -
                      (promo?.promocodeAmount > 0 ? promo.promocodeAmount : 0)
                    ).toFixed(2)}
                  </strong>
                </li>
              </ul>
            </div>
          </Container>
        </section>
        {/* wrapper end */}
        <>
          <Footer
            isPaymentPage
            paymentOption={null}
            tipAmount={tip}
            serviceCharge={serviceCharge}
            promoCode={promo?.promocodeAmount}
            total={total}
            handleGooglePayOrApplePay={() => {
              handleSave()
            }}
            promocodeId={promo?.promocodeId}
            promocodeAmount={promo?.promocodeAmount}
          />
          {splitByGuestComponent}
        </>
      </Layout>
      <AlertDialog
        open={openAlertDialog}
        onClose={() => setOpenALertDialog(false)}
        splitMsg={splitMsg}
      />
      <PaymentProcessDialog
        payToBeDoneRes={payToBeDoneRes}
        open={openPaymentProcessDialog}
        onClose={() => {
          setOpenPaymentProcessDialog(false)
        }}
        totalToBePaid={totalToBePaid}
        tip={tip}
        customTip={tip}
        isCustomTip={isCustomTip}
        paymentAmount={totalToBePaid}
        tipPercentage={selectedTipPercentage}
        itemListPay={items}
        promocodeId={promo?.promocodeId}
        promocodeAmount={promo?.promocodeAmount}
        noOfPayingGuest={noOfPayingGuests || 1}
      />
    </>
  )
}

export default Payment
