import { AxiosResponse } from 'axios'
import { toast } from 'react-toastify'
import { IFaqUrlResponse, ILogRequest } from 'interfaces/common'
import { ISaveProfile } from 'interfaces/login'
import {
  IGoogleLocationIdRequestDTO,
  IGooglePlaceIdResponseDTO,
  ILoginIdRequest,
  IPaymentIdRequest,
  IPaymentReceiptListData,
  IPaymentReceiptRequestDTO,
} from 'interfaces/receipt'
import {
  IGuestContactFormRequest,
  IGuestEstablishmentRequest,
  IGuestEstablishmentResponse,
  IGuestUnsubscribeRequest,
} from 'interfaces/payment'
import {
  IRatingUpdateRequestDTO,
  ITabsRatingRequest,
  ITabsResponse,
} from '../interfaces/tabs'
// eslint-disable-next-line import/no-cycle
import httpClient from './base-service'
import { IApiSuccessResponse } from '../interfaces/api-success-response'

const endPointBaseURL = `/Guest`

const rateExperience = async (
  requestBody: ITabsRatingRequest
): Promise<AxiosResponse<IApiSuccessResponse<ITabsResponse>>> =>
  httpClient.post<IApiSuccessResponse<ITabsResponse>>(
    `${endPointBaseURL}/save-rate-experience`,
    requestBody
  )

const updateRateExperience = async (
  requestBody: IRatingUpdateRequestDTO
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/update-rate-experience`,
    requestBody
  )

// update Profile
const updateProfile = async (
  requestBody: ISaveProfile
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.put<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/update-guest-data`,
    requestBody
  )

const addLog = async (
  requestBody: ILogRequest
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(`/common/add-log`, requestBody)

const getGuestProfile = async (requestBody: {
  loginId: number
}): Promise<AxiosResponse<IApiSuccessResponse<ISaveProfile>>> =>
  httpClient.post<IApiSuccessResponse<ISaveProfile>>(
    `${endPointBaseURL}/get-guest-data`,
    requestBody
  )
const getFaqUrl = async (): Promise<
  AxiosResponse<IApiSuccessResponse<IFaqUrlResponse>>
> =>
  httpClient.get<IApiSuccessResponse<IFaqUrlResponse>>(`/Common/get-faq-url`, {
    hideLoader: true,
  })

const getAllReceipt = async (
  requestBody: ILoginIdRequest
): Promise<AxiosResponse<IApiSuccessResponse<IPaymentReceiptListData>>> =>
  httpClient.post<IApiSuccessResponse<IPaymentReceiptListData>>(
    `${endPointBaseURL}/get-guest-receipt-list`,
    requestBody
  )

const getReceiptDetails = async (
  requestBody: IPaymentIdRequest
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/get-guest-receipt-data`,
    requestBody
  )

const GetPlaceIdLocationName = async (
  requestBody: IGoogleLocationIdRequestDTO
): Promise<AxiosResponse<IApiSuccessResponse<IGooglePlaceIdResponseDTO>>> =>
  httpClient.post<IApiSuccessResponse<IGooglePlaceIdResponseDTO>>(
    `${endPointBaseURL}/get-google-location-name-by-placeid`,
    requestBody
  )

const SendDemoPaymentEmail = async (
  requestBody: IPaymentReceiptRequestDTO
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/send-demo-payment-email`,
    requestBody
  )

const GetGuestEst = async (
  requestBody: IGuestEstablishmentRequest
): Promise<AxiosResponse<IApiSuccessResponse<IGuestEstablishmentResponse>>> =>
  httpClient.post<IApiSuccessResponse<IGuestEstablishmentResponse>>(
    `${endPointBaseURL}/get-guest-establishment`,
    requestBody
  )

const UpdateGuestEstablishmentOptIn = async (
  requestBody: IGuestEstablishmentRequest
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/update-guest-opt-in`,
    requestBody
  )

const GuestUnsubscribe = async (
  requestBody: IGuestUnsubscribeRequest
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/unsubscribe`,
    requestBody,
    {
      hideLoader: false,
    }
  )

const ContactForm = async (
  requestBody: IGuestContactFormRequest
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `/GuestMarketing/contact-form`,
    requestBody,
    {
      hideLoader: false,
    }
  )

const addLogAsync = async (msg: string) => {
  try {
    await addLog({
      message: msg,
    })
  } catch (error) {
    toast.error('Error sending log via api: please contact support')
  }
}

export default {
  rateExperience,
  updateProfile,
  getGuestProfile,
  getFaqUrl,
  addLog,
  addLogAsync,
  getAllReceipt,
  getReceiptDetails,
  GetPlaceIdLocationName,
  updateRateExperience,
  SendDemoPaymentEmail,
  GetGuestEst,
  UpdateGuestEstablishmentOptIn,
  GuestUnsubscribe,
  ContactForm,
}
