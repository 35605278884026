import { Button } from '@mui/material'

import { Pencil } from 'assets/images'
import { ITabDetail } from 'interfaces/tab'
import { useChangeSplit } from './hooks/useChangeSplit'

interface Props {
  tabDetails: ITabDetail
}

function ChangeSplit(props: Props) {
  const { tabDetails } = props
  const { onChangeSplit, alertDialogComponent } = useChangeSplit(tabDetails)

  if (
    !(tabDetails?.tabPayments == null || !tabDetails?.isServiceChargeAvailable)
  ) {
    return null
  }

  return (
    <div className="edit-split">
      <Button
        color="primary"
        className="change-split"
        variant="outlined"
        title="Change Split"
        onClick={() => {
          onChangeSplit()
        }}
      >
        <img src={Pencil} alt="Pencil" />
        <span>Change Split</span>
      </Button>
      {alertDialogComponent}
    </div>
  )
}

export { ChangeSplit }
