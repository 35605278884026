import { useCallback, useEffect, useState } from 'react'

import { useStripe as useExternalStripe } from '@stripe/react-stripe-js'
import { IRestaurantDetailData } from 'interfaces/restaurant'
import { PaymentRequest, PaymentRequestOptions } from '@stripe/stripe-js'
import { PAYMENT_OPTIONS } from 'enums/payment-options'
import { useDispatch, useSelector } from 'react-redux'

import { tabDetail } from 'features/tab/tab'
import { setPaymentInProcess } from 'features/paymentProgress/paymentProgress'
import { restuarantData } from 'features/restaurant/restaurant'
import paymentService from 'services/payment.service'

const getDefaultOptions = (
  restaurantData: IRestaurantDetailData
): PaymentRequestOptions => ({
  country: 'CA',
  currency: restaurantData?.currencyName.toLocaleLowerCase(),
  total: {
    label: 'Demo total',
    amount: 100,
  },
  requestPayerName: true,
  requestPayerEmail: true,
})

function useStripe() {
  const dispatch = useDispatch()
  const stripe = useExternalStripe()

  const restaurantData = useSelector(restuarantData)
  const tabDetails = useSelector(tabDetail)

  const [availableOption, setAvailableOption] = useState<PAYMENT_OPTIONS>(
    PAYMENT_OPTIONS.CARD
  )
  const [paymentRequest, setPaymentRequest] =
    useState<PaymentRequest>(undefined)

  /* Google Pay & Apple Pay Payment Method Starts */

  const checkPaymentOption = useCallback(() => {
    if (stripe == null) {
      return
    }
    const pr = stripe.paymentRequest(getDefaultOptions(restaurantData))
    if (pr) {
      setPaymentRequest(pr)
      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          // eslint-disable-next-line no-console
          let mode = PAYMENT_OPTIONS.CARD
          if (result.applePay) {
            mode = PAYMENT_OPTIONS.APPLE_PAY
          } else if (result.googlePay) {
            mode = PAYMENT_OPTIONS.GOOGLE_PAY
          }
          setAvailableOption(mode)
        }
      })
    }
  }, [stripe, restaurantData])

  const abortPaymentProcess = async (payOption?) => {
    dispatch(setPaymentInProcess(false))
    await paymentService.clearPaymentRecords({
      tabId: tabDetails?.tabId,
    })
    if (
      payOption &&
      (payOption === PAYMENT_OPTIONS.APPLE_PAY ||
        payOption === PAYMENT_OPTIONS.GOOGLE_PAY)
    ) {
      window.location.reload()
    }
  }

  useEffect(() => {
    checkPaymentOption()
  }, [checkPaymentOption])

  return {
    stripe,
    paymentRequest,
    availableOption,
    abortPaymentProcess,
    checkPaymentOption,
  }
}

export { useStripe }
