import { SplitType } from 'enums/split-type'

import { seats, tabDetail, tabItems } from 'features/tab/tab'

import { useSelector } from 'react-redux'
import { ITabItem } from 'interfaces/tab'
import { useSplitDetail } from './useSplitDetail'

export function useItems() {
  const { isSplit, splitType } = useSplitDetail()

  const items = useSelector(tabItems)
  const selectedSeats = useSelector(seats)
  const tabDetails = useSelector(tabDetail)

  const getSeatSplitItems = (): ITabItem[] => {
    let selectedsSeatsArray = []
    let selectedSeatItems = []
    let conCatedArray = []
    selectedsSeatsArray = selectedSeats.filter((el) => el.isSelected)
    selectedSeatItems = selectedsSeatsArray.map((v) => v.items)
    conCatedArray = [].concat(...selectedSeatItems)
    return conCatedArray
  }

  const getCheckSplitItmes = (): ITabItem[] => {
    const ret = []
    tabDetails?.orders.forEach((ele) => {
      if (ele.isSelected) {
        ret.push(...ele.items.filter((d) => !d.isPaid))
      }
    })
    return ret
  }

  const getItems = (): ITabItem[] => {
    if (isSplit) {
      switch (splitType) {
        case SplitType.Guest:
          return []
        case SplitType.Full:
          return items.filter((item) => !item.isPaid)
        case SplitType.Seat:
          return getSeatSplitItems()
        case SplitType.Check:
          return getCheckSplitItmes()
        default:
          return items.filter((item) => item.isSelected && !item.isPaid)
      }
    }
    return items
  }

  return { items: getItems() }
}
