import { useAlertDialog } from 'hooks/useAlertDialog'
import { ITabDetail } from 'interfaces/tab'
import { useNavigate } from 'react-router-dom'

import paymentService from 'services/payment.service'

function useChangeSplit(tabDetails: ITabDetail) {
  const navigate = useNavigate()
  const { alertDialogComponent, onOpen, onMsgChange } = useAlertDialog()

  const onChangeSplit = async () => {
    const result = await paymentService.confirmIsPaymentDone(tabDetails?.tabId)
    if (!result.data.data.isPaymentDone && !result.data.data.isTabLocked) {
      navigate('/your-tab', {
        state: {
          isFromSplit: true,
        },
      })
    } else if (result.data.data.isPaymentDone) {
      onMsgChange(
        'You can not change split as full/partial payment already made for tab.'
      )
      onOpen()
    } else if (result.data.data.isTabLocked) {
      onMsgChange(
        'It looks like someone is trying to pay for your tab! Click "Ok" to be redirected to the main tab.'
      )
      onOpen()
    }
  }

  return { onChangeSplit, alertDialogComponent }
}

export { useChangeSplit }
