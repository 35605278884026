import { useState } from 'react'

import AlertDialog from 'components/AlertDialog/Index'

function useAlertDialog() {
  const [isOpen, setIsOpen] = useState(false)
  const [msg, setMsg] = useState('')

  const onToggle = () => {
    setIsOpen((prev) => !prev)
  }

  const onOpen = () => {
    setIsOpen(true)
  }

  return {
    isOpen,
    onToggle,
    onOpen,
    onMsgChange: (val: string) => setMsg(val),
    alertDialogComponent: (
      <AlertDialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        splitMsg={msg}
      />
    ),
  }
}

export { useAlertDialog }
