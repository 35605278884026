import {
  tabSubtotal,
  tabTax,
  setTabTax,
  setTabSubtotal,
} from 'features/tab/tab'
import { useDispatch, useSelector } from 'react-redux'

function useTabTotals() {
  const dispatch = useDispatch()
  const subtotal = useSelector(tabSubtotal)
  const tax = useSelector(tabTax)

  const handleSubtotalChange = (val: number) => {
    dispatch(setTabSubtotal(val))
  }

  const handleTaxChange = (val: number) => {
    dispatch(setTabTax(val))
  }

  return {
    subtotal,
    tax,
    total: subtotal + tax,
    onSubtotalChange: handleSubtotalChange,
    onTaxChange: handleTaxChange,
  }
}

export { useTabTotals }
