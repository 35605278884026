import { Promo } from 'components/Promo'
import { PromoCode } from 'interfaces/promocode'
import { useState } from 'react'
import paymentService from 'services/payment.service'
import { useItems } from './useItems'

function usePromo() {
  const { items } = useItems()

  const establishmentId = sessionStorage.getItem('establishmentId')

  const subTotal = items.reduce((prevValue, item) => {
    return prevValue + item.price
  }, 0)

  const [promo, setPromo] = useState<PromoCode | undefined>()
  const [error, setError] = useState<string>()
  const [input, setInput] = useState<string>('')

  const handlePromoClick = async () => {
    const promoPattern = /^[a-zA-Z0-9]*$/
    if (input === '' || input == null) {
      setError('Empty Field')
    } else if (!promoPattern.test(input)) {
      setError(
        'Please fill in the promo code field with no special characters or spaces'
      )
    } else {
      const result = await paymentService.getPromoCode({
        establishmentId: Number(establishmentId),
        codeName: input,
        loginId: localStorage.getItem('loginId')
          ? Number(localStorage.getItem('loginId'))
          : 0,
        amount: Number(subTotal?.toFixed(2)),
      })
      if (result.data.data === null || result.data.data === undefined) {
        setError('Oops, promo code invalid or expired')
      } else {
        // Successful response
        setError(undefined)
        setPromo({
          ...result.data.data,
          codeName: result.data.data.codeName.toUpperCase(),
          promocodeAmount: Number(
            (result.data.data.isFixedAmountPromoCode
              ? result.data.data.promocodeAmount
              : (result.data.data.promocodePercentage * subTotal) / 100
            ).toFixed(2)
          ),
        })
      }
    }
  }

  const handleDelete = async () => {
    setPromo(undefined)
  }

  const handleOnChange = (value: string) => {
    setInput(value)
  }

  return {
    promo,
    promoComponent: (
      <Promo
        promoCode={promo}
        input={input}
        error={error}
        onClick={handlePromoClick}
        onChange={handleOnChange}
        onDelete={handleDelete}
      />
    ),
  }
}

export { usePromo }
